/**
 * Map JS file.
 */
var map;
var center  = new google.maps.LatLng(49.8795371, 20.9824222);
var markers = [];
var markerCluster;

function initMap(settings, mapVar) {
  var baseUrl = Drupal.settings.ejn.baseUrl;
  mapVar.viewName      = 'rest_views';
  mapVar.$filterWrapper       = jQuery('.map-filters');
  mapVar.$filter       = mapVar.$filterWrapper.find('select').not('#edit-field-network-type');
  var $gridWrapper    = jQuery('.grid-wrapper');

  google.maps.event.addDomListener(window, 'load', function () {
    var $calendarBlock = jQuery('#block-views-events-restyle-block-2');

    if ($calendarBlock.length > 0) {
      $gridWrapper.before('<div id="map-calendar-wrapper"></div>');
      var $mapCalendarWrap = jQuery('#map-calendar-wrapper');
      $mapCalendarWrap.append('<div id="' + mapVar.mapID + '" class="map-wrapper"></div>');
    }
    else {
      $gridWrapper.before('<div id="' + mapVar.mapID + '" class="map-wrapper"></div>');
    }
    buildMap(baseUrl, mapVar);

    if ($calendarBlock.length > 0) {
      setTimeout(function() {
        $mapCalendarWrap.append('<div id="calendar-wrapper" class="calendar-wrapper"></div>');
        var $calendarWrapper = jQuery('#calendar-wrapper');
        $calendarBlock.prependTo($calendarWrapper);
      }, 500);
      setTimeout(function() {
        map.setCenter(new google.maps.LatLng(55.050877, 16.5230389));
        jQuery('.fullcalendar').fullCalendar('next');
        jQuery('.fullcalendar').fullCalendar('prev');

        var mapMarginTop = jQuery('.fullcalendar').find('.fc-header').height() + parseInt(jQuery('.fullcalendar').find('.fc-header').css('margin-bottom'));
        jQuery('#calendar-map').css({
          'margin-top': mapMarginTop
        });
        var mapHeight = jQuery('#calendar-map').height();
        jQuery('.fc-border-separate').css({
          'height': mapHeight + 'px',
          'max-height': mapHeight + 'px'
        });

        var trCount = jQuery('.fullcalendar').find('.fc-content').find('tbody').find('tr').length;
        var threadHeight = jQuery('.fullcalendar').find('.fc-content').find('thead').height();
        var trHeight = (mapHeight - threadHeight) / trCount;
        jQuery('.fullcalendar').find('.fc-content').find('tbody').find('tr').css({
          height: trHeight + 'px'
        });
        jQuery('.fullcalendar').fullCalendar('refetchEvents');

        jQuery('#blank-loading').remove();
      }, 800);

    }
    jQuery('.switch-wrapper label[for=state-map]').addClass('h-active');
  });

  mapVar.$filter.off('change').on('change', function () {
    deleteMarkers();
    markerCluster.clearMarkers();
    buildMap(baseUrl, mapVar);
  });

  mapVar.$filterWrapper.find('#edit-combine').off('keyup').keyup(function(event) {
    if (event.keyCode === 13) {
      deleteMarkers();
      markerCluster.clearMarkers();
      buildMap(baseUrl, mapVar);
    }
  });
}


function buildMap(baseUrl, mapVar) {
  if (map === undefined) {
    map = new google.maps.Map(document.getElementById(mapVar.mapID), {
      center: center,
      zoom: 3,
      minZoom: 3,
      maxZoom: 18,
      zoomControl: true,
      zoomControlOptions: {
        position: google.maps.ControlPosition.RIGHT_CENTER
      },
      // navigationControl: false,
      // mapTypeControl: false,
      // scaleControl: false,
      streetViewControl: false,
      // scrollwheel: false,
      // draggable: true,
      disableDefaultUI: true,
      styles: [
        {
          "featureType": "administrative",
          "elementType": "all",
          "stylers": [{"visibility": "on"}, {"lightness": 33}]
        }, {
          "featureType": "landscape",
          "elementType": "all",
          "stylers": [{"color": "#f7f7f7"}]
        }, {
          "featureType": "poi.business",
          "elementType": "all",
          "stylers": [{"visibility": "off"}]
        }, {
          "featureType": "poi.park",
          "elementType": "geometry",
          "stylers": [{"color": "#deecdb"}]
        }, {
          "featureType": "poi.park",
          "elementType": "labels",
          "stylers": [{"visibility": "on"}, {"lightness": "25"}]
        }, {
          "featureType": "road",
          "elementType": "all",
          "stylers": [{"lightness": "25"}]
        }, {
          "featureType": "road",
          "elementType": "labels.icon",
          "stylers": [{"visibility": "off"}]
        }, {
          "featureType": "road.highway",
          "elementType": "geometry",
          "stylers": [{"color": "#ffffff"}]
        }, {
          "featureType": "road.highway",
          "elementType": "labels",
          "stylers": [{"saturation": "-90"}, {"lightness": "25"}]
        }, {
          "featureType": "road.arterial",
          "elementType": "all",
          "stylers": [{"visibility": "on"}]
        }, {
          "featureType": "road.arterial",
          "elementType": "geometry",
          "stylers": [{"color": "#ffffff"}]
        }, {
          "featureType": "road.local",
          "elementType": "geometry",
          "stylers": [{"color": "#ffffff"}]
        }, {
          "featureType": "transit.line",
          "elementType": "all",
          "stylers": [{"visibility": "off"}]
        }, {
          "featureType": "transit.station",
          "elementType": "all",
          "stylers": [{"visibility": "off"}]
        }, {
          "featureType": "water",
          "elementType": "all",
          "stylers": [{"visibility": "on"}, {"color": "#e0f1f9"}]
        }
      ]
    });
  }

  var oms = new OverlappingMarkerSpiderfier(map, {
    markersWontMove: true,
    markersWontHide: true,
    basicFormatEvents: true,
    keepSpiderfied: true,
    circleFootSeparation: 30
  });

  var locations = {};
  var viewArgs  = '';
  mapVar.$filter.each(function (index) {
    if (index > 0) {
      viewArgs += '-';
    }
    viewArgs += jQuery(this).val();
  });
  viewArgs = viewArgs.toLowerCase();
  if (viewArgs === '') {
    viewArgs  = 'all';
  }
  jQuery.ajax({
    url: baseUrl + '/map' + '/' + mapVar.viewName + '/' + mapVar.viewDisplayID + '/' + viewArgs,
    type: 'post',
    success: function (data) {
      jQuery.each(data, function (index) {
        if (locations[this.nid] == undefined || locations[this.nid] == null) {

          var $combineFilter = jQuery('#edit-combine');
          var thisTitle = 1;
          if ($combineFilter.length > 0) {
            if ($combineFilter.val() !== '') {
              thisTitle = this.node_title;
              if ($combineFilter.val().length > 3) {
                if (thisTitle.toLowerCase().indexOf($combineFilter.val().toLowerCase()) >= 0) {
                  thisTitle = 1;
                }
              }
            }
          }

          if (this.field_location !== '' && thisTitle === 1) {
            var location        = {};
            location.nid        = this.nid;
            location.title      = this.node_title;
            location.lat        = this.location_latitude;
            location.lng        = this.location_longitude;
            locations[this.nid] = location;
          }
        }
      });

      var prev_infowindow = false;
      for (var key in locations) {
        var location      = locations[key];
        var contentString = '<div class="gm-style-iw"><div class="location-content nid-' + location.nid + '"><a href="' + baseUrl + '/node/' + location.nid + '">' + location.title + '</a></div></div>';

        var icon = 'sites/all/themes/custom/sixtheme/dist/images/icons/map-pin.svg';
        // @todo enable to set different pin on HQ.
        // if (location.type === 'page') {
        //     icon = '/images/icons/map-pin.svg'
        // }

        var markerPosition = new google.maps.LatLng(location.lat, location.lng);
        var marker        = new google.maps.Marker({
          position: markerPosition,
          icon: icon,
          type: location.type,
          map: map
        });

        marker.infowindow = new google.maps.InfoWindow({
          content: contentString
        });

        marker.addListener('click', function () {
          // if (prev_infowindow) {
        //     prev_infowindow.close();
        //   }
        //
        //   prev_infowindow = this.infowindow;
        //   this.infowindow.open(map, this);
        });
        markers.push(marker);


        google.maps.event.addListener(marker, 'spider_click', function(e) {
          if (prev_infowindow) {
            prev_infowindow.close();
          }

          prev_infowindow = this.infowindow;
          this.infowindow.open(map, this);
        });

        oms.addMarker(marker);
      }

      markerCluster = new MarkerClusterer(map, markers, {
        averageCenter: true,
        styles: [
          {
            width: 53,
            height: 53,
            url: 'sites/all/themes/custom/sixtheme/dist/images/icons/markercluster/m.svg'
          }
        ]
      });

      markerCluster.setMaxZoom(17);

      google.maps.event.addListener(map, 'zoom_changed', function () {
        var zoomLevel = map.getZoom();
        if (zoomLevel <= 3) {
          map.setCenter(center);
        }
      });
    }
  });
}

// Sets the map on all markers in the array.
function setMapOnAll(map) {
  for (var i = 0; i < markers.length; i++) {
    markers[i].setMap(map);
  }
}

// Removes the markers from the map, but keeps them in the array.
function clearMarkers() {
  setMapOnAll(null);
}

// Shows any markers currently in the array.
function showMarkers() {
  setMapOnAll(map);
}

// Deletes all markers in the array by removing references to them.
function deleteMarkers() {
  clearMarkers();
  markers = [];
}
