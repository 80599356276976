/**
 * @file
 * members JS behaviour.
 */

 (function ($) {
  Drupal.behaviors.navigation = {
    attach: function (context, settings) {

      if (matchMedia) {
        var mq = window.matchMedia("(max-width: 1023px)");
        mq.addListener(WidthChange);
        WidthChange(mq);
      }

      function WidthChange(mq) {
        if (mq.matches) {
          small();
        } else {
          large();
        }
      }

      function small() {
        $('#logo').prependTo('.header-wrapper');
        $('#block-ejn-global-user-custom-block').prependTo('.header-mobile');

        $('#block-system-main-menu .expanded > a').on('click', function (e) {
          var $subNav = $(this).next();
          e.stopPropagation();
          e.preventDefault();

          if ($subNav.hasClass('active')) {
            $subNav.removeClass('active');
          }
          else {
            $subNav.addClass('active');
          }
        });

      }

      function large() {

        $('#logo').prependTo('#header .section');
        $('#block-ejn-global-user-custom-block').appendTo('.region-header');

        // Sticky header.
        var controllerNav = new ScrollMagic.Controller();
        var scene = new ScrollMagic.Scene({
          triggerElement: '.header-content > #navigation',
          triggerHook: 0
        })
        .setPin('.header-content > #navigation')
        .setClassToggle('#block-system-main-menu', 'fixed')
        .addTo(controllerNav);

      }

      // Search - main menu.
      $('#mainnav-search').on('click', function (e) {
        e.stopPropagation();
        e.preventDefault();

        var $searchBlock = $('#block-search-form');
        if ($searchBlock.hasClass('active')) {
          $searchBlock.removeClass('active');
          TweenLite.to($searchBlock, 0.2, {height: 0});
        }
        else {
          $searchBlock.addClass('active');
          TweenLite.set($searchBlock, {height: "auto"});
          TweenLite.from($searchBlock, 0.2, {height: 0});
        }
      });

      // Mobile nav.

      $('#btn-open-nav').on('click', function (e) {
        if ($('body').hasClass('open-menu-mobile')) {
          $('body').removeClass('open-menu-mobile');
          $('.header-wrapper').removeClass('h-is-open');
          $(this).removeClass('is-active');
        }
        else {
          $('body').addClass('open-menu-mobile');
          $('.header-wrapper').addClass('h-is-open');
          $(this).addClass('is-active');
        }
      });


    }
  }
})(jQuery);
