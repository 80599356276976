/**
 * @file
 * sidebar JS behaviour.
 */

 (function ($) {
  Drupal.behaviors.sidebar = {
    attach: function (context, settings) {

      var controllerSidebar = null;

      if (matchMedia) {
        var mq = window.matchMedia("(max-width: 1023px)");
        mq.addListener(WidthChange);
        WidthChange(mq);
      }

      function WidthChange(mq) {
        if (mq.matches) {
          small();
        } else {
          large();
        }
      }

      function small() {
        if( controllerSidebar !== null && controllerSidebar !== undefined ) {
          controllerSidebar = controllerSidebar.destroy( true );
          // Remove sidebar pin-spacers
          $('.sidebar-first > .scrollmagic-pin-spacer').contents().unwrap();
        }
      }

      // Sticky sidebar if country guides
      function large() {
        if ($('.node-type-country-guide, .page-country-guides').length > 0) {
          var vh = $(window).height();
          var $sidebar = $('.sidebar-first > .section');
          var sidebarHeight = $sidebar.outerHeight();
          var navHeight = $('#navigation').height();
          var contentHeight = $('#content').height();
          var contentInnerHeight = $('#content .section').height();
          var offsetFix = 20;

          if ( contentInnerHeight > sidebarHeight && (vh - offsetFix - navHeight) > sidebarHeight  ) {
            controllerSidebar = new ScrollMagic.Controller();
            var scene = new ScrollMagic.Scene({
              triggerElement: $sidebar[0],
              offset: -(navHeight) - offsetFix,
              triggerHook: 0,
              duration: contentHeight - sidebarHeight - offsetFix
            })
            .setPin($sidebar[0])
            .setClassToggle('.sidebar-first', 'fixed')
            .addTo(controllerSidebar);
          }
        }

      }

    }
  }
})(jQuery);
