/**
 * @file
 * Festival JS behaviour.
 */

(function ($) {
  Drupal.behaviors.calendar = {
    attach: function (context, settings) {

      // Map.
      if ($('.view-events-restyle.view-display-id-page_1').length > 0) {
        var mapVar           = {};
        mapVar.mapID         = 'calendar-map';
        mapVar.viewDisplayID = 'block_6';
        initMap(settings, mapVar);
      }
    }
  }
})(jQuery);
