/**
 * @file
 * members JS behaviour.
 */

(function ($) {
  Drupal.behaviors.members = {
    attach: function (context, settings) {

      // Map.
      if ($('.view-members-restyle.view-display-id-page').length > 0) {
        var mapVar           = {};
        mapVar.mapID         = 'members-map';
        mapVar.viewDisplayID = 'block_3';
        initMap(settings, mapVar);
      }
    }
  }
})(jQuery);
