/**
 * @file
 * Custom JS behaviour for sixtheme base theme.
 */

(function ($) {

  // Result + switch map.
  $(window).load(function () {
    var $viewHeader         = $('.view-header');
    var $exposedFilters     = $('div[id^="block-views-exp"]');
    var $calendarPage       = $('#block-system-main').find('.content');
    var mapHeaderAttachment = $exposedFilters;
    var $calendarBlock      = jQuery('#block-views-events-restyle-block-2');

    if ($exposedFilters.length > 0) {

      if ($viewHeader.length > 0) {
        mapHeaderAttachment.after('<div class="map-header"><div class="result-count">' + $viewHeader.html() + '</div></div>');
        // $viewHeader.hide();
      }
      else {
        mapHeaderAttachment.after('<div class="map-header"><div class="result-count">' + Drupal.t('No results found') + '</div></div>');
      }
    }
    else if ($('#block-views-events-restyle-block-2').length > 0) {
      mapHeaderAttachment = $calendarPage;
      if ($viewHeader.length > 0) {
        mapHeaderAttachment.prepend('<div class="map-header"><div class="result-count">' + $viewHeader.html() + '</div></div>');
        $viewHeader.hide();
      }
      else {
        mapHeaderAttachment.prepend('<div class="map-header"><div class="result-count">' + Drupal.t('No results found') + '</div></div>');
      }
    }

    // Maps.
    // Switch map.
    if ($('.map-filters').length > 0) {
      if ($('.switch-wrapper').length === 0) {
        if ($('.view-id-festivals_restyle.view-display-id-page').length > 0) {
          $('.result-count').after('<div class="switch-container">' +
            '<div class="switch-wrapper">' +
            '<input id="state-map" name="state-map" type="radio" checked="checked">' + '<label for="state-map" onclick="">' + Drupal.t('Map') + '</label>' + '<input id="state-calendar" name="state-calendar" type="radio">' + '<label for="state-calendar" onclick="">' + Drupal.t('Calendar') + '</label>' + '<input id="state-grid" name="state-grid" type="radio">' + '<label for="state-grid" onclick="">' + Drupal.t('Grid') + '</label>' + '</div>' + '</div>');
        }
        else {
          $('.result-count').after('<div class="switch-container">' +
            '<div class="switch-wrapper">' +
            '<input id="state-map" name="state-map" type="radio">' +
            '<label for="state-map" onclick="">' + Drupal.t('Map') + '</label>' +
            '<input id="state-grid" name="state-grid" type="radio">' +
            '<label for="state-grid" onclick="">' + Drupal.t('Grid') + '</label>' +
            '</div>' +
            '</div>');
        }
      }

      $('.switch-wrapper label').off('click').on('click', function (e) {
        var $allLabels       = $('.switch-wrapper label');
        var $mapWrapper      = $('.map-wrapper');
        var $calendarWrapper = $('#block-views-festivals-restyle-block-4');
        switch ($(this).prop('for')) {
          case'state-calendar':
            $allLabels.removeClass('h-active');
            $(this).addClass('h-active');
            $calendarWrapper.removeClass('h-invisible');
            if ($calendarWrapper.hasClass('h-hide')) {
              $calendarWrapper.removeClass('h-hide');
            }
            if (!$mapWrapper.hasClass('h-hide')) {
              $mapWrapper.addClass('h-hide');
            }
            break;

          case'state-map':
            $allLabels.removeClass('h-active');
            $(this).addClass('h-active');
            if ($mapWrapper.hasClass('h-hide')) {
              $mapWrapper.removeClass('h-hide');
            }
            if (!$calendarWrapper.hasClass('h-hide')) {
              $calendarWrapper.addClass('h-hide');
            }
            break;

          case'state-grid':
            $allLabels.removeClass('h-active');
            $(this).addClass('h-active');
            if (!$calendarWrapper.hasClass('h-hide')) {
              $calendarWrapper.addClass('h-hide');
            }
            if (!$mapWrapper.hasClass('h-hide')) {
              $mapWrapper.addClass('h-hide');
            }
            break;
        }
        if (!$(this).hasClass('h-active')) {
          $(this).addClass('h-active');
          $('.switch-wrapper').find('li').toggleClass('h-active');
        }
        else {
          e.preventDefault();
        }

      });
    }

    // Button filter for exposed filters.
    if ($('.views-exposed-form').length > 0) {
      if ($('.btn-filter').length === 0) {
        $('.views-exposed-form').prepend('<button class="btn-filter" type="button"><span class="btn-show">Show filters</span><span class="btn-hide">Hide filters</span></button>');
      }

      $('.btn-filter').on('click', function () {
        $(this).toggleClass('h-open');
      });
    }

    // Button for sidebar news menu.
    if ($('#block-menu-menu-news').length > 0) {
      var activeNews = $('#block-menu-menu-news .active').html();
      if ($('.btn-sidebar').length === 0) {
        $('#block-menu-menu-news').prepend('<button class="btn-sidebar">' + activeNews + '</button>');
      }

      $('.btn-sidebar').on('click', function () {
        $(this).toggleClass('h-open');
      });
    }

  });

  Drupal.behaviors.sixtheme = {
    attach: function (context, settings) {
      var baseUrl = Drupal.settings.ejn.baseUrl;
      var link = '';
      if (Drupal.settings.ejn.nodeType !== undefined) {
        var nodeType = Drupal.settings.ejn.nodeType;
        switch (nodeType) {
          case 'project':
            link = 'projects';
            break;

          case 'profile':
            if (Drupal.settings.ejn.nodeMember === true) {
              link = 'members';
            }
            if (Drupal.settings.ejn.nodeTypology !== undefined) {
              switch (Drupal.settings.ejn.nodeTypology) {
                case 'organisation':
                  if (Drupal.settings.ejn.nodeMember === false) {
                    link = 'organisations';
                  }
                  break;

                case 'agency':
                  link = 'agencies';
                  break;

                case 'media':
                  link = 'media-profiles';
                  break;

                case 'basic_profile':
                  break;

              }
            }

            break;

          case 'event':
            link = 'events';
            break;

          case 'festival':
            link = 'festivals';
            break;

          case 'club':
          case 'venue':
            link = 'clubs';
            break;

          case 'news':
          case 'press_release':
            link = 'news';
            break;
        }
      }

      if (Drupal.settings.ejn.viewPath !== undefined) {
        link = Drupal.settings.ejn.viewPath;
        if (link === 'members-festivals' || link === 'members-clubs') {
          link = 'members';
        }

        switch (link) {
          case 'press-releases':
          case 'members-news':
          case 'agencies-news':
          case 'clubs-news':
          case 'festivals-news':
          case 'media-news':
          case 'organisations-news':
            link = 'news';
            break;
        }
      }

      if (link !== '') {
        var $mainMenu = $('#block-system-main-menu');
        $mainMenu.find('a[href="/' + link + '"]').addClass('active-trail');
        if ($mainMenu.find('a[href="/' + link + '"]').closest('ul').siblings('a').length > 0) {
          $mainMenu.find('a[href="/' + link + '"]').closest('ul').siblings('a').addClass('active-trail');
        }
      }

      // Result count views update.
      var $viewHeader = $('.view-header');
      if ($viewHeader.length > 0) {
        $('.result-count').remove();
        $('.map-header').prepend('<div class="result-count">' + $viewHeader.html() + '</div>');
        if (!$('.view-country-guides-content').length > 0) {
          $viewHeader.hide();
        }
      }

      var $networkSelect = $('#edit-field-network-type');
      $(window).load(function () {
        $(document).on('change', '#edit-field-network-type', function () {
          var path = '/our-network';
          switch ($networkSelect.val()) {
            case '0':
              path = '/members';
              break;
            case '1':
              path = '/members-festivals';
              break;
            case '2':
              path = '/members-clubs';
              break;
          }
          window.location = baseUrl + path;
        });
      });

      // Twitter block.
      if ($('#twitter-block').length > 0) {
        $.ajax('tweets', {
          success: function (data) {
            $('#twitter-block').find('.content').append('<ul id="twitter-slick"></ul>');
            for (var i = 0; i < data.length; i++) {
              var item = data[i];
              var text = '';
              var date = moment.utc(item.created_at);

              if (item.hasOwnProperty('retweeted_status')) {
                date = moment.utc(item.retweeted_status.created_at);
              }

              var formattedDate = date.format('D MMMM');
              var userName      = item.user.screen_name;
              var content       = '';

              content += '<span class="twitter-user"><a href="https://www.twitter.com/' + userName + '">@' + userName + '</a></span>';
              content += '<span class="twitter-text">' + linkify(item.full_text) + '</span>';
              content += '<span class="twitter-date">' + formattedDate + '</span>';
              $('#twitter-slick').append('<li>' + content + '</li>');
            }
            $('#twitter-slick').slick({
              infinite: true,
              slidesToShow: 3,
              slidesToScroll: 1,
              dots: true,
              responsive: [
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                  }
                },
                {
                  breakpoint: 768,
                  settings: {
                    arrows: false,
                    slidesToShow: 1,
                    slidesToScroll: 1
                  }
                }]
            });
          },
          error: function () {

          }
        });
      }

      $('#block-ejn-users-user-admin-menu').find('a.active').closest('li').addClass('active');

      if ($('.view-events-restyle.view-display-id-block_2').length > 0) {
        $('.view-events-restyle.view-display-id-block_2').off().on('click', function (e) {
          var mapHeight = $('#calendar-map').height();
          $('.fc-border-separate').css({
            'height': mapHeight + 'px',
            'max-height': mapHeight + 'px'
          });

          var trCount = $('.fullcalendar').find('.fc-content').find('tbody').find('tr').length;
          var trHeight = mapHeight / trCount;
          $('.fullcalendar').find('.fc-content').find('tbody').find('tr').css({
            'height': trHeight + 'px'
          });
          $('.fullcalendar').fullCalendar( 'refetchEvents' );
        });

      }

      var $slickSlider = $('.slick__slider');
      if (!$slickSlider.hasClass('height-processed')) {
        if ($slickSlider.length > 0) {
          $slickSlider.each(function () {
            var height = 0;
            $(this).find('.slick-slide').each(function (index) {
              if ($(this).height() > height) {
                height = $(this).height();
              }
            });

            $(this).find('.slick-slide .ds-1col').height(height);
            $(this).addClass('height-processed');
          });
        }
      }


    }
  };
})(jQuery);

// Twitter functions.
function linkify(text) {
  text = text.replace(
    /((https?\:\/\/)|(www\.))(\S+)(\w{2,4})(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/gi,
    function (url) {
      var full_url = !url.match('^https?:\/\/') ? 'http://' + url : url;
      return '<a href="' + full_url + '">' + url + '</a>';
    }
  );

  text = text.replace(/(^|\s)@(\w+)/g, '$1@<a href="http://www.twitter.com/$2">$2</a>');
  text = text.replace(/(^|\s)#(\w+)/g, '$1#<a href="http://twitter.com/search/%23$2">$2</a>');
  return text;
}
