/**
 * @file
 * Clubs JS behaviour.
 */

(function ($) {
  Drupal.behaviors.clubs = {
    attach: function (context, settings) {

      // Map.
      if ($('.view-clubs-restyle.view-display-id-page').length > 0) {
        var mapVar           = {};
        mapVar.mapID         = 'clubs-map';
        mapVar.viewDisplayID = 'block_2';
        initMap(settings, mapVar);
      }
    }
  }
})(jQuery);
