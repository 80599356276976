/**
 * @file
 * Network Festival JS behaviour.
 */

(function ($) {
  Drupal.behaviors.networkFestivals = {
    attach: function (context, settings) {

      // Map.
      if ($('.view-festivals-restyle.view-display-id-page_1').length > 0) {
        var mapVar           = {};
        mapVar.mapID         = 'network-festivals-map';
        mapVar.viewDisplayID = 'block_4';
        initMap(settings, mapVar);
      }
    }
  }
})(jQuery);
