(function ($) {
  Drupal.behaviors.social = {
    attach: function (context, settings) {

      var social = $('.field-name-field-social-links .field-item');

      if (social.length > 0) {

        social.each(function() {

          var url = $(this).find('a').attr('href');
          var className = "other";

          if(url.indexOf('facebook') !== -1) {
            className = "facebook";
          } else if(url.indexOf('twitter') !== -1) {
            className = "twitter";
          } else if(url.indexOf('instagram') !== -1) {
            className = "instagram";
          } else if(url.indexOf('linkedin') !== -1) {
            className = "linkedin";
          } else if(url.indexOf('youtube') !== -1) {
            className = "youtube";
          } else if(url.indexOf('spotify') !== -1) {
            className = "spotify";
          }

          $(this).addClass(className);

        });

      }
    }
  }
})(jQuery);
