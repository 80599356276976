/**
 * @file
 * front JS behaviour.
 */

 (function ($) {
  Drupal.behaviors.front = {
    attach: function (context, settings) {
      $('html').addClass('js');

      if ($('.front').length > 0) {

        // Header.
        var $logoEJN = $('#logo');
        var $logoEJN_words = $logoEJN.find('.words > path');
        var $logoEJN_graphicFirst = $logoEJN.find('.graphics .first');
        var $logoEJN_graphicMiddle = $logoEJN.find('.graphics .middle');
        var $logoEJN_graphicLast = $logoEJN.find('.graphics .last');

        var headerTL = new TimelineLite();
        headerTL.to($logoEJN, 1, {autoAlpha: 1, y: 0}, 0)
        .staggerFrom($logoEJN_words, 1, {autoAlpha: 0, y: -20}, .15, .5)
        .from($logoEJN_graphicFirst, .3, {autoAlpha: 0, y: 10}, 1)
        .from($logoEJN_graphicMiddle, .3, {autoAlpha: 0, y: -10}, 1.15)
        .from($logoEJN_graphicLast, .3, {autoAlpha: 0, y: 10}, 1.3);

        // init controller scrollmagic
        var controller = new ScrollMagic.Controller();

        // GENERIC BLOCK ANIMATIONS.
        // Block views.
        $('.block-views, #block-ejn-global-twitter-block').each(function() {
          var $block = $(this);
          var $slick = $(this).find('.slick-slider');
          var $slickSlides = $slick.find('.slick-slide.slick-active');
          var $blockPlus = $block.find('.slick__arrow, .slick-dots, .more-link');

          var tlBlock = new TimelineLite({paused: true})
          .from($block.find('h2'), .75, { y: 10, autoAlpha: 0, ease: Sine.easeOut }, 0)
          .staggerFrom($slickSlides, 1.25, { autoAlpha: 0, ease: Sine.easeOut }, .25, '-=.25')
          .staggerFrom($blockPlus, 1, { autoAlpha: 0 }, .25, '-=1');

          var scene = new ScrollMagic.Scene({
            triggerElement: $block[0],
            triggerHook: .8
          })
          .on("enter", function (event) {
            tlBlock.play().timeScale(1);
          })
          .on("leave", function (event) {
            tlBlock.reverse().timeScale(1.5);
          })
          .addTo(controller);
        });

        // Block framed.
        $('.c-block-framed').each(function() {
          var $block = $(this);
          var $blockTitle = $(this).find('.c-title-framed');
          var $blockFooter = $(this).find('.c-footer-framed');

          // Title
          var tlFrameTitle = new TimelineLite({paused: true})
          .from($blockTitle.find('.c-title-frame-graphic'), 0.1, { autoAlpha: 0 }, 0)
          .from($blockTitle.find('.c-title-frame-graphic'), .5, { width: '0%' })
          .from($blockTitle.find('.c-title-frame-graphic'), .15, { height: '0%' })

          .from($blockFooter.find('.c-footer-frame-graphic'), 0.1, { autoAlpha: 0 }, '+=.25')
          .from($blockFooter.find('.c-footer-frame-graphic'), .15, { height: '0%' })
          .from($blockFooter.find('.c-footer-frame-graphic'), .35, { width: '0%' });

          new ScrollMagic.Scene({
            triggerElement: $block[0],
            triggerHook: .8
          })
          .on("enter", function (event) {
            tlFrameTitle.play().timeScale(1);
          })
          .on("leave", function (event) {
            tlFrameTitle.reverse().timeScale(1.5);
          })
          .addTo(controller);

        });

        // CUSTOM BLOCK ANIMATIONS.
        // Block join ejn - Graphic
        var $blockJoin = $('#join-ejn');
        var $blockJoinLogo = $('#join-ejn').find('.join-ejn-logo');

        new ScrollMagic.Scene({
          triggerElement: $blockJoin[0],
          triggerHook: 1,
          duration: '100%'
        })
        .setTween($blockJoin.find('.graphic-element'), {yPercent: -20, ease: Linear.easeNone})
        .addTo(controller);

        // Block join ejn - Content
        joinTL = new TimelineLite({ paused: true })
        .staggerFrom($blockJoin.find('.block-body > div'), .75, { autoAlpha: 0, yPercent: 25 }, .35, 0)
        .from($blockJoin.find('.button'), 1, { autoAlpha: 0 }, '-=.5');


        joinLogoTL = new TimelineLite({ paused: true })
        .from($blockJoinLogo, 1, { y: 30 }, 0)
        .staggerFrom($blockJoinLogo.find('.word'), .75, { autoAlpha: 0 }, -.35, 0)
        .from($blockJoinLogo.find('.border-top > *'), .75, { drawSVG: 0 }, 0)
        .from($blockJoinLogo.find('.border-bottom > *'), .75, { drawSVG: 0 }, .5);

        new ScrollMagic.Scene({
          triggerElement: $blockJoin[0],
          triggerHook: 0.5
        })
        .on("enter", function (event) {
          joinTL.play(0).timeScale(1);
          joinLogoTL.play(0).timeScale(1);
        })
        .on("leave", function (event) {
          joinTL.reverse().timeScale(2);
          joinLogoTL.reverse().timeScale(2);
        })
        .addTo(controller);

        // Block Festivals.
        var $blockFestival = $('#block-views-festivals-restyle-block-1');

        new ScrollMagic.Scene({
          triggerElement: $blockFestival[0],
          triggerHook: 1,
          duration: '150%'
        })
        .setTween($blockFestival.find('.graphic-element'), {yPercent: -20, ease: Linear.easeNone})
        .addTo(controller);

      }

    }
  }
})(jQuery);
